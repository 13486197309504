import dynamic from 'next/dynamic';

import AppLayout from '../components/layouts/layout';
const Hero = dynamic(() => import('../components/home/Hero'));
const OurServices = dynamic(() => import('../components/service/OurServices'));
const Industries = dynamic(() => import('../components/home/Industries'));
const TechStack = dynamic(() => import('../components/home/TechStack'));
const BitverxWork = dynamic(() => import('../components/home/BitverxWork'));
const BusinessGoal = dynamic(() => import('../components/home/BusinessGoal'));
const Testimonal = dynamic(() => import('../components/common/Testimonal'));
const Ideas = dynamic(() => import('../components/home/Ideas'));
const Clients = dynamic(() => import('../components/home/Clients'))


const Home = () => {
  return (
    <AppLayout activeLink="Home">
      <Hero />
      <Clients />
      <OurServices />
      <Industries />
      <TechStack />
      <BusinessGoal />
      <BitverxWork />
      <Testimonal />
      <Ideas />
    </AppLayout>
  );
};

export default Home;
